<template>
	<!-- 完成订单 -->
	<div>
		<van-overlay :show="showLoading">
			<div class="wrapper" @click.stop>
				<van-loading size="24px" vertical>上传中...</van-loading>
			</div>
		</van-overlay>

		<van-sticky>
			<van-nav-bar title="完成订单" left-text="返回" left-arrow @click-left="goback" />
		</van-sticky>
		<!-- 表单 -->
		<van-form style="text-align: left" ref="completeRules">
			<!--      <van-cell title="安装地区" value="江苏-苏州" />-->
			<van-field
				label="下单充电桩类型"
				:value="orderDetail.storeName"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="品牌"
				:value="orderDetail.deviceBrandName"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="车型"
				:value="orderDetail.modelName"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="所属区域"
				:value="orderDetail.regionName"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="所属公司"
				:value="orderDetail.companyName"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="订单类型"
				:value="orderTypeFormat(orderDetail)"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="维修类型"
				:value="repairTypeFormat(orderDetail)"
				v-show="orderDetail.orderType === 2"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="原订单号"
				:value="orderDetail.oldOrderNum"
				input-align="right"
				readonly
				v-show="orderDetail.orderType === 2"
			/>
			<van-field
				label="预约单号"
				:value="orderDetail.pileModel"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="客户姓名"
				:value="orderDetail.customerName"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				label="手机号码"
				:value="orderDetail.phone"
				input-align="right"
				readonly
				label-width="8rem"
			/>
			<van-field
				v-model="orderDetail.detailAddress"
				label="详细地址"
				input-align="right"
				placeholder="请填写详细地址"
				clearable
				type="textarea"
				rows="1"
				required
				autosizeinput-align="right"
				label-width="8rem"
				:rules="[{ required: true }]"
			/>
			<van-field label="车架号" :value="orderDetail.frameNo" input-align="right" readonly />
			<van-field
				label="故障描述"
				:value="orderDetail.faultDescription"
				input-align="right"
				readonly
				v-show="orderDetail.orderType === 2"
			/>
			<van-field label="订单备注" :value="orderDetail.orderRemark" input-align="right" readonly />
			<van-field label="跟进备注" label-width="5rem" input-align="left" rows="5" readonly>
				<template #button>
					<div style="height: 5rem; overflow: auto;">
						<div
							v-for="(item, index) in followList"
							style="width: 200px; text-align: left; margin: 0"
							:key="index"
						>
							{{formattedFollowInfo(item)}}
						</div>
					</div>
				</template>
			</van-field>

			<van-field
				label="原充电桩编号"
				:value="oldDeviceNum"
				input-align="right"
				readonly
				v-show="orderDetail.orderType === 2"
			/>
			<van-field
				v-model="orderDetail.deviceNum"
				v-show="checkShowDeviceNum(orderDetail)"
				required
				clearable
				label="充电桩编号"
				input-align="right"
				label-width="5rem"
				placeholder="请录入充电桩编号"
				:rules="[
					{
						required: checkShowDeviceNum(orderDetail),
					},
				]"
				readonly
				@click="showChangeDevice = true"
			>
				<!-- <template #button>
          <van-button native-type="button" @click='scanCode()' type="primary" size="small">扫描录入</van-button>
          <van-button native-type="button" @click='deviceListIsShow = true' type="primary"  size="small">现有设备</van-button>
          <van-button native-type="button" @click='showDeviceCategory = true' type="primary"  size="small">现有设备</van-button>
        </template> -->
			</van-field>
			<van-field
				input-align="right"
				v-show="orderDetail.deviceNum !== ''"
				readonly
				v-model="orderDetail.categoryName"
				required
				label="充电桩类型"
				placeholder="请选择充电桩类型"
				@click="categoryListIsShow = false"
				:rules="[{ required: required }]"
			/>
			<van-field
				input-align="right"
				v-show="true"
				readonly
				clickable
				:value="selfPickupStr"
				label="是否自提桩"
				placeholder="点击选择是否用户自提桩"
				@click="showSelfPickup = true"
			/>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail)"
				readonly
				v-model="orderDetail.powerSource"
				required
				label="电源来源"
				placeholder="请选择电池来源"
				@click="showSource = true"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail)"
				readonly
				v-model="orderDetail.cableBrandNameTmp"
				required
				label="电缆品牌"
				placeholder="请选择电缆品牌"
				@click="showCableBrand = true"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail) && showCableBrandOther"
				v-model="orderDetail.cableBrandName"
				required
				label="其他电缆品牌"
				placeholder="请填写电缆品牌"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail)"
				v-model="orderDetail.lineLength"
				required
				label="总共使用电缆"
				placeholder="请填写用线长度"
				type="number"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail)"
				v-model="orderDetail.addOutlay"
				required
				label="总增项费用"
				placeholder="请填写增项费用"
				type="number"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail)"
				readonly
				clickable
				required
				:value="signExemptionStr"
				label="是否签免责"
				placeholder="点击选择用户是否签免责"
				@click="showChoice1 = true"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field
					input-align="right"
					v-show="false"
					readonly
					clickable
					required
					:value="isSelfPickup"
					label="是否自提桩"
					placeholder="点击选择是否用户自提桩"
					@click="showChoiceX = true"
					:rules="[{ required: required }]"
			/>

			<van-field
					input-align="right"
					v-show="checkShowUnSelfPickup(orderDetail)"
					readonly
					clickable
					:value="isWiring"
					label="是否自布线"
					placeholder="点击选择是否自布线"
					@click="showChoiceY = true"
			/>
			<van-field
					input-align="right"
					v-show="checkShowUnSelfPickup(orderDetail)"
					readonly
					clickable
					:value="houseType"
					label="住宅类型"
					placeholder="点击选择住宅类型"
					@click="showChoiceZ = true"
			/>

			<van-field
				input-align="right"
				v-show="orderDetail.signExemption === 1 && checkShowUnSelfPickup(orderDetail)"
				required
				name="reserve3Uploader"
				label="免责图"
			>
				<template #input>
					<van-uploader
						:multiple="true"
						:max-count="40"
						v-model="orderImage.reserve3Uploader"
						:before-read="imageUploadOuther"
						@click-upload="flagUpload = true"
						:name="-2"
						:before-delete="deleteImage"
					/>
				</template>
			</van-field>
			<van-field
				input-align="right"
				v-show="checkShowUnSelfPickup(orderDetail)"
				label-width="10rem"
				readonly
				clickable
				required
				:value="purchaseExtensionStr"
				label="是否购买延长线"
				placeholder="点击选择用户是否购买延长线"
				@click="showChoice2 = true"
				:rules="[{ required: required && checkRequiredUnSelfPickup(orderDetail) }]"
			/>
			<van-field label="超出后物料" label-width="5rem" v-show="orderDetail.isSelfPickup !== 1">
				<template #button>
					<van-button native-type="button" type="primary" size="small" @click="showMaterial = true"
						>添加物料</van-button
					>
				</template>
			</van-field>
			<van-row>
				<van-col span="4" align="center" class="row-text">类型</van-col>
				<van-col span="6" align="center" class="row-text">型号</van-col>
				<van-col span="4" align="center" class="row-text">单价</van-col>
				<van-col span="4" align="center" class="row-text">数量</van-col>
				<van-col span="4" align="center" class="row-text">实收</van-col>
				<van-col span="2" align="center" class="row-text">操作</van-col>
			</van-row>
			<van-row
				align="center"
				v-for="(item, index) in orderDetail.materialOrderList"
				:key="index"
				v-show="orderDetail.isSelfPickup !== 1"
			>
				<van-col span="4" align="center"><van-field :label="item.typeName" /></van-col>
				<van-col span="6" align="center"><van-field :label="item.modelName" /></van-col>
				<van-col span="4" align="center"
					><van-field
						type="number"
						input-align="center"
						v-model="item.meterialPrice"
						@change="calcMaterialTotal(item)"
					></van-field
				></van-col>
				<van-col span="4" align="center"
					><van-field
						type="number"
						input-align="center"
						placeholder="数量"
						v-model="item.num"
						@change="calcMaterialTotal(item)"
				/></van-col>
				<van-col span="4" align="center"
					><van-field
						type="number"
						input-align="center"
						placeholder="实收金额"
						v-model="item.actualAmount"
				/></van-col>
				<van-col span="2" align="center"
					><van-cell style="color: red" title="×" @click="deleteMaterial(index)"
				/></van-col>
			</van-row>
			<van-field
				input-align="right"
				:value="changeTime(orderDetail.installTime)"
				clickable
				:label="installTimeLable"
				placeholder="请选择时间"
				required
				readonly
				@click="
					showTime = true;
					currentDate = new Date();
				"
				:rules="[{ required: required }]"
			/>
			<van-field
				input-align="right"
				label-width="10rem"
				v-show="orderDetail.orderType === 2 && orderDetail.repairType === 1"
				readonly
				v-model="oldDisposeTypeStr"
				required
				label="旧充电桩处理方式"
				placeholder="请选择旧充电桩处理方式"
				@click="showDisposeType = true"
				:rules="[
					{ required: required && orderDetail.orderType === 2 && orderDetail.repairType === 1 },
				]"
			/>
			<van-field
				input-align="right"
				v-model="orderCourier.courierNum"
				label="快递单号"
				placeholder="请填写快递单号"
				clearable
				type="input"
				required
				autosizeinput-align="right"
				label-width="8rem"
				v-show="orderDetail.oldDisposeType === 2"
				:rules="[{ required: required && orderDetail.oldDisposeType === 2 }]"
			/>
			<van-field
				input-align="right"
				required
				name="courierUploader"
				label="快递单据图"
				v-show="orderDetail.oldDisposeType === 2"
				:rules="[
					{ required: required && orderDetail.oldDisposeType === 2, message: '请上传快递单据图' },
				]"
			>
				<template #input>
					<van-uploader
						v-model="orderCourier.courierImageUploader"
						:before-read="imageUpload"
						@click-upload="flagUpload = true"
						:name="-4"
						:before-delete="deleteImage"
					/>
				</template>
			</van-field>

			<div v-for="img in imageSetting.templateDetailList" v-bind:key="img.id">
				<van-field
					input-align="right"
					:required="img.isRequired === 1"
					:name="'tmpUploader' + img.id"
					:label="img.imageName"
					:rules="[
						{ required: img.isRequired === 1 && required, message: '请上传' + img.imageName },
					]"
				>
					<template #input>
						<van-uploader
							v-model="img.imageUploader"
							:before-read="imageUpload"
							@click-upload="flagUpload = true"
							:name="img.id"
							:before-delete="deleteImage"
						/>
					</template>
				</van-field>
			</div>

			<van-field
				input-align="right"
				required
				name="surveyUploader"
				label="勘测确认单"
				:rules="[{ required: required, message: '请上传勘测确认单' }]"
			>
				<template #input>
					<van-uploader
						:multiple="true"
						:max-count="40"
						v-model="orderImage.surveyImageUploader"
						:before-read="imageUploadOuther"
						:name="-3"
						:before-delete="deleteImage"
					/>
				</template>
			</van-field>

			<van-field input-align="right" name="otherUploader" label="其他多图">
				<template #input>
					<van-uploader
						:multiple="true"
						:max-count="40"
						v-model="orderImage.reserve1Uploader"
						:before-read="imageUploadOuther"
						:name="-1"
						:before-delete="deleteImage"
					/>
				</template>
			</van-field>

			<van-field input-align="right" name="videoUploader" label="相关视频">
				<template #input>
					<van-uploader
						accept="video/*"
						v-model="orderImage.videoUploader"
						:name="-99"
						:after-read="afterRead"
						:before-delete="deleteImage"
					>
						<template slot="preview-cover">
							<video
								width="100%"
								type="video/mp4"
								:src="transformUrl(orderDetail.videoUrl)"
								muted="muted"
								preload="preload"
								controls="controls"
							></video>
						</template>
					</van-uploader>
				</template>
			</van-field>
			<van-cell
				style="color: red"
				v-show="orderDetail.status === 4"
				title="驳回理由"
				:value="orderDetail.rejectRemark"
			/>
			<div style="margin: 1rem" v-if="orderDetail.status === 2 || orderDetail.status === 4">
				<van-button
					round
					block
					type="warning"
					size="small"
					native-type="submit"
					@click="onSubmit(0)"
					>暂存订单</van-button
				>
			</div>
			<div style="margin: 1rem" v-if="orderDetail.status === 2 || orderDetail.status === 4">
				<van-button round block type="info" size="small" native-type="submit" @click="onSubmit(1)"
					>完成订单</van-button
				>
			</div>
			<div
				style="margin: 1rem"
				v-if="orderDetail.status === 3 && authorization('/home-admin/order/order-manage/examine')"
			>
				<van-button
					round
					block
					type="info"
					size="small"
					native-type="button"
					@click="toExamine(orderDetail.id)"
					>审核订单</van-button
				>
			</div>
		</van-form>
		<!-- 弹出层 -->
		<van-popup v-model="showSource" position="bottom">
			<van-picker
				title="电源来源"
				show-toolbar
				:columns="powerSourceCol"
				@confirm="onSource"
				@cancel="showSource = false"
			/>
		</van-popup>
		<van-popup v-model="showCableBrand" position="bottom">
			<van-picker
				title="电缆品牌"
				value-key="brandName"
				show-toolbar
				:columns="cableBrandCol"
				@confirm="onCableBrand"
				@cancel="showCableBrand = false"
			/>
		</van-popup>
		<van-popup v-model="showChoice1" position="bottom">
			<van-picker
				value-key="label"
				show-toolbar
				:columns="choiceList1"
				@confirm="onChoice1"
				@cancel="showChoice1 = false"
			/>
		</van-popup>
		<van-popup v-model="showChoice1" position="bottom">
			<van-picker
				value-key="label"
				show-toolbar
				:columns="choiceList1"
				@confirm="onChoice1"
				@cancel="showChoice1 = false"
			/>
		</van-popup>

		
		<van-popup v-model="showChoiceX" position="bottom">
			<van-picker
					value-key="label"
					show-toolbar
					:columns="choiceListX"
					@confirm="onChoiceX"
					@cancel="showChoiceX = false"
			/>
		</van-popup>

		
		<van-popup v-model="showChoiceY" position="bottom">
			<van-picker
					value-key="label"
					show-toolbar
					:columns="choiceListY"
					@confirm="onChoiceY"
					@cancel="showChoiceY = false"
			/>
		</van-popup>

		
		<van-popup v-model="showChoiceZ" position="bottom">
			<van-picker
					value-key="houseTypeName"
					show-toolbar
					:columns="houseTypeList"
					@confirm="onChoiceZ"
					@cancel="showChoiceZ = false"
			/>
		</van-popup>






		<van-popup v-model="deviceListIsShow" position="bottom">
			<van-picker
				value-key="label"
				show-toolbar
				:columns="deviceList"
				@confirm="onDeviceList"
				@cancel="deviceListIsShow = false"
			/>
		</van-popup>
		<van-popup v-model="categoryListIsShow" position="bottom">
			<van-picker
				value-key="categoryName"
				show-toolbar
				:columns="deviceCategoryCol"
				@confirm="onCategoryList"
				@cancel="categoryListIsShow = false"
			/>
		</van-popup>
		<van-popup v-model="showChoice2" position="bottom">
			<van-picker
				value-key="label"
				show-toolbar
				:columns="choiceList2"
				@confirm="onChoice2"
				@cancel="showChoice2 = false"
			/>
		</van-popup>
		<van-popup v-model="showSelfPickup" position="bottom">
			<van-picker
				value-key="label"
				show-toolbar
				:columns="commonBoolChoiceList"
				@confirm="onSelfPickUp"
				@cancel="showSelfPickup = false"
			/>
		</van-popup>
		<!--    <van-popup v-model="showArea" position="bottom">
      <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false"/>
    </van-popup>-->
		<van-popup v-model="showTime" position="bottom">
			<van-datetime-picker
				type="datetime"
				title="请选择时间"
				v-model="currentDate"
				@confirm="onTime"
				@cancel="showTime = false"
			/>
		</van-popup>
		<van-popup v-model="showMaterial" position="bottom">
			<van-cascader
				v-model="materialModelValue"
				title="请选择消耗的物料"
				:options="materialList"
				@close="showMaterial = false"
				@finish="onFinish"
			/>
		</van-popup>
		<van-popup v-model="showDeviceCategory" position="bottom">
			<van-cascader
				v-model="deviceCategoryValue"
				title="请选择充电桩"
				:options="deviceCategoryList"
				@close="showDeviceCategory = false"
				@finish="onDeviceCategory"
			/>
		</van-popup>
		<van-popup v-model="showDisposeType" position="bottom">
			<van-picker
				title="旧充电桩处理方式"
				show-toolbar
				value-key="label"
				:columns="disposeTypeList"
				@confirm="onDisposeType"
				@cancel="showDisposeType = false"
			/>
		</van-popup>
		<van-popup v-model="showExamine" position="bottom">
			<van-field
				v-model="examineParams.rejectRemark"
				rows="6"
				autosize
				label="驳回理由"
				type="textarea"
				maxlength="80"
				placeholder="驳回需要填写驳回理由"
				show-word-limit
			/>
			<van-button type="danger" style="margin: 1rem; float: right" @click="confirmExamine(4)"
				>驳回</van-button
			>
			<van-button type="primary" style="margin: 1rem; float: right" @click="confirmExamine(6)"
				>通过</van-button
			>
		</van-popup>
		<van-popup v-model="showChangeDevice" position="bottom">
			<van-field
				style="position: absolute; top: 35px; margin: auto; z-index: 99"
				placeholder="搜索"
				v-model="searchDevice"
				input-align="center"
				@input="onInput"
			></van-field>
			<van-picker
				show-toolbar
				:columns="changeDeviceList"
				value-key="deviceNumAndCategoryName"
				@confirm="classChangeDevice"
				@cancel="showChangeDevice = false"
			/>
		</van-popup>
	</div>
</template>
<script>
	import { areaList } from '@vant/area-data';
	import moment from 'moment';
	import { Toast } from 'vant';
	import ImageCompressor from 'compressorjs';
	import { authorization } from '../authorization/authorization';
	import { orderTypeFormat, repairTypeFormat } from '../components/common/format';
	export default {
		data() {
			return {
				showLoading: false,
				showChangeDevice: false,
				searchDevice: '',
				goBackUrl: this.$route.query.goBackUrl,
				goBackType: this.$route.query.type,
				showExamine: false,
				examineParams: {
					orderIds: [],
					orderId: '',
					rejectRemark: '',
					type: '',
				},
				showDeviceCategory: false,
				categoryListIsShow: false,
				powerSourceCol: ['独立电表', '物业取电', '自家电表', '其他'],
				cableBrandCol: [],
				currentDate: new Date(),
				deviceListIsShow: false,
				flagUpload: true,
				signExemptionStr: '',
				isDockingRequired: '',
				isSelfPickup: '',
				isWiring: '',
				houseType: '',
				purchaseExtensionStr: '',
				selfPickupStr: '',
				oldDisposeTypeStr: '',
				required: true,
				orderId: sessionStorage.getItem('orderId'),
				deviceNum: this.$route.query.deviceNum,
				installTimeLable: '实际安装时间',
				orderDetail: {},
				changeDeviceList: [],
				oldChangeDeviceList: [],
				followList: [],
				androidAttrs: null,
				oldDeviceNum: '',
				orderCourier: {
					orderId: '',
					courierNum: '',
					courierImageUrl: '',
					courierImageUploader: [],
				},
				//存储图片预览路径
				orderImage: {
					mainImageUploader: [],
					sideImageUploader: [],
					junctionBoxImageUploader: [],
					groupPhotoImageUploader: [],
					drivingLicenseImageUploader: [],
					surveyImageUploader: [],
					installConfirmImageUploader: [],
					toolBlanketImageUploader: [],
					reserve1Uploader: [],
					reserve3Uploader: [],
					videoUploader: [],
				},
				uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
				imageSetting: {},
				orderImageDatas: [],
				showArea: false,
				showTime: false,
				showSource: false,
				showCableBrand: false,
				showCableBrandOther: false,
				showChoice1: false,
				showChoice2: false,
				showMaterial: false,
				showChoiceX: false,
				showChoiceY: false,
				showChoiceZ: false,
				showSelfPickup: false,
				showDisposeType: false,
				areaList,
				houseTypeList: [
					{
						id: 0,
						houseTypeName: '农村自建房'

					},
					{
						id: 1,
						houseTypeName: '城市小区'
					},
					{
						id: 2,
						houseTypeName: '城市自建房'
					},
					{
						id: 3,
						houseTypeName: '工厂'
					},
					{
						id: 4,
						houseTypeName: '其他'
					}


				],

				choiceListX:[
					{
						value: 0,
						label: '否'

					},
					{
						value: 1,
						label: '是'

					}
				],
				choiceListY:[
					{
						value: 0,
						label: '否'

					},
					{
						value: 1,
						label: '是'

					}
				],
				choiceList1: [
					{
						value: 0,
						label: '未签',
					},
					{
						value: 1,
						label: '已签',
					},
				],
				choiceList2: [
					{
						value: 0,
						label: '未购买',
					},
					{
						value: 1,
						label: '已购买',
					},
				],
				commonBoolChoiceList: [
					{
						value: 1,
						label: '是',
					},
					{
						value: 0,
						label: '否',
					},
				],
				disposeTypeList: [
					{
						value: 1,
						label: '保留',
					},
					{
						value: 2,
						label: '邮寄',
					},
				],
				installationTime: '',
				materialList: [],
				materialModelValue: '',
				deviceCategoryValue: '',
				deviceList: [],
				deviceCategoryCol: [],
				deviceCategoryList: [],
			};
		},
		created() {
			//字典
			this.selMaterialType();
			this.getDeviceList();
			this.queryCategoryDrown();
			this.queryCableBrandDrown();
			this.selDeviceCategory();
			//订单数据
			this.selectOrderDetail(true);
			//跟进数据
			this.selectFollowDetail();
			// 在页面一进来时就去判断当前使用机型
			const model = navigator.userAgent;
			this.androidAttrs = model.indexOf('Android') > -1 || model.indexOf('Linux') > -1;
		},
		methods: {
			authorization,
			orderTypeFormat,
			repairTypeFormat,
			confirmExamine(type) {
				if (type === 4) {
					if (
						this.examineParams.rejectRemark === '' ||
						this.examineParams.rejectRemark === undefined
					) {
						Toast.fail('请填写驳回理由');
						return;
					}
				}
				this.examineParams.type = type;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/examine-order',
					data: this.examineParams,
				}).then((res) => {
					if (res.data.code === 200) {
						if (type == 4) {
							Toast.success('驳回成功');
						} else if (type == 6) {
							Toast.success('审核通过');
						}
						this.selectOrderDetail(false);
						this.showExamine = false;
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			toExamine(id) {
				this.examineParams.orderId = id;
				this.examineParams.orderIds = [];
				this.examineParams.orderIds.push(id);
				this.showExamine = true;
			},
			queryCategoryDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
				}).then((res) => {
					this.deviceCategoryCol = res.data.data.list;
				});
			},
			queryCableBrandDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/cable-brand/select-cable-brand',
				}).then((res) => {
					this.cableBrandCol = res.data.data.list;
					this.cableBrandCol.push({ id: -1, brandName: '其他' });
				});
			},
			getFollowRemarkList(followRemark) {
				if (followRemark === undefined || followRemark === null) {
					return [];
				}
				return followRemark.split(';');
			},
			dateFormat(time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			getDeviceList() {
				this.axios({
					method: 'get',
					url: '/v1/h5/device-manage/list-device-drown',
				}).then((res) => {
					this.deviceList = res.data.data;
				});
			},
			checkDate(time) {
				if (time === undefined || time === null) {
					return new Date();
				}
				return new Date(time);
			},
			/**
			 * 删除物料
			 * @param index
			 */
			deleteMaterial(index) {
				this.orderDetail.materialOrderList.splice(index, 1);
			},
			onDeviceCategory({ selectedOptions }) {
				this.orderDetail.categoryId = selectedOptions[0].value;
				this.orderDetail.categoryName = selectedOptions[0].text;
				this.orderDetail.deviceNum = selectedOptions[1].text;
				this.showDeviceCategory = false;
			},
			onFinish({ selectedOptions }) {
				let obj = {};
				obj.typeId = selectedOptions[0].value;
				obj.typeName = selectedOptions[0].text;
				obj.modelId = selectedOptions[1].value;
				obj.modelName = selectedOptions[1].text;
				obj.num = 1;
				if (this.orderDetail.materialOrderList === undefined) {
					this.orderDetail.materialOrderList = [];
				}
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-material-id',
					params: {
						id: obj.modelId,
					},
				}).then((res) => {
					let price = res.data.data.price;
					if (price === undefined || price === null) {
						price = 0;
					}
					obj.meterialPrice = price;
					obj.actualAmount = obj.meterialPrice * obj.num;
					this.orderDetail.materialOrderList.push(obj);
					this.showMaterial = false;
					this.materialModelValue = '';
				});
			},
			//获取物料类型
			selMaterialType() {
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-material-type-drown',
				}).then((res) => {
					this.materialList = res.data.data;
				});
			},
			selDeviceCategory() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-device-category',
				}).then((res) => {
					this.deviceCategoryList = res.data.data;
				});
			},
			goback() {
				//返回上一页
				sessionStorage.removeItem('orderId');
				this.$router.push({ path: this.goBackUrl, query: { type: this.goBackType } });
			},
			onSource(value) {
				//电池来源确认
				this.orderDetail.powerSource = value;
				this.showSource = false;
			},
			onDisposeType(value) {
				this.orderDetail.oldDisposeType = value.value;
				this.oldDisposeTypeStr = value.label;
				this.showDisposeType = false;
			},
			onCableBrand(value) {
				//电缆品牌确认
				this.orderDetail.cableBrandId = value.id;
				this.orderDetail.cableBrandName = value.brandName;
				this.orderDetail.cableBrandNameTmp = value.brandName;
				if (this.orderDetail.cableBrandId === -1) {
					this.orderDetail.cableBrandName = '';
					this.showCableBrandOther = true;
				} else {
					this.showCableBrandOther = false;
				}

				this.showCableBrand = false;
			},
			onChoiceX(value){
				this.orderDetail.isSelfPickup = value.value;
				this.isSelfPickup = value.label;
				this.showChoiceX = false;
			},

			onChoiceY(value){
				this.orderDetail.isWiring = value.value;
				this.isWiring = value.label;
				this.showChoiceY = false;
			},
			onChoiceZ(pickerValue) {
				const selectedHouseItem = this.houseTypeList.find(item => item.houseTypeName === pickerValue.houseTypeName);
				this.houseType = pickerValue.houseTypeName;
				if (selectedHouseItem) {
					this.selectedHouseTypeId = selectedHouseItem.id;  // 仅保存住宅类型的 id
					//console.log('选择的住宅类型 ID:', this.selectedHouseTypeId);
				}
				this.orderDetail.houseType = this.selectedHouseTypeId
				this.showChoiceZ = false; // 选择后关闭弹窗
			},

			onChoice1(value) {
				//是否签免责确认
				this.orderDetail.signExemption = value.value;
				this.signExemptionStr = value.label;
				this.showChoice1 = false;
			},
			onChoice2(value) {
				//是否购买延长线确认
				this.orderDetail.purchaseExtension = value.value;
				this.purchaseExtensionStr = value.label;
				this.showChoice2 = false;
			},
			onSelfPickUp(value) {
				this.orderDetail.isSelfPickup = value.value;
				this.selfPickupStr = value.label;
				this.isSelfPickup = value.value;
				this.showSelfPickup = false;
				if(this.isSelfPickup == 1){
					this.orderDetail.orderType = 3;
					this.setOrderType();
					this.selectImageSetting();
					
				}
				console.log(this.imageSetting);
				
				
			},
			setOrderType(){
				this.axios({
					method: 'put',
					url: '/v1/web/order-info/update-order-type',
					params: {
						id: this.orderId,
						orderType: 3
					},
				}).then((res) => {
					if (res.data.code == 200) {
						return;
					}
				})
			},
			onDeviceList(value) {
				this.orderDetail.deviceNum = value.label.substring(0, value.label.indexOf('('));
				this.deviceListIsShow = false;
			},
			onCategoryList(value) {
				this.orderDetail.categoryId = value.id;
				this.orderDetail.categoryName = value.categoryName;
				//切换充电桩类型的时候，刷新下模板
				if (this.categoryListIsShow) {
					this.selectImageSetting();
				}

				this.categoryListIsShow = false;
			},
			onTime(value) {
				//实际安装实际确认
				//this.installationTime=moment(value).format("YYYY-MM-DD HH:mm:ss");
				this.orderDetail.installTime = value.getTime();
				this.showTime = false;
			},
			changeTime(time) {
				if (time === null) {
					//return moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
					return '';
				}
				return moment(time).format('YYYY-MM-DD HH:mm:ss');
			},
			printHouseTypeName(houseType){
				if(houseType == 0){
					return '农村自建房';
				}
				if(houseType == 1){
					return '城市小区';
				}
				if(houseType == 2){
					return '城市自建房';
				}
				if(houseType == 3){
					return '工厂';
				}
				if(houseType == 4){
					return '其他';
				}

			},
			selectOrderDetail(loadImageSetting) {
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-order-detail',
					params: {
						id: this.orderId,
					},
				}).then(async (res) => {
					let resultData = res.data.data;
					this.orderDetail = resultData;
					this.isWiring = this.orderDetail.isWiring ? '是':'否';
					this.houseType = this.printHouseTypeName(this.orderDetail.houseType);
					if (this.orderDetail.categoryId == null) {
						this.orderDetail.categoryId = this.orderDetail.storeId;
						this.orderDetail.categoryName = this.orderDetail.storeName;
					}

					this.getDeviceByCategory();

					if (this.deviceNum !== undefined) {
						this.orderDetail.deviceNum = this.deviceNum;
					}

					if (this.orderDetail.cableBrandId == -1) {
						this.orderDetail.cableBrandNameTmp = '其他';
						this.showCableBrandOther = true;
					} else {
						this.orderDetail.cableBrandNameTmp = this.orderDetail.cableBrandName;
						this.showCableBrandOther = false;
					}

					let type;
					switch (this.orderDetail.orderType) {
						case 2:
							type = '维修';
							break;
						case 3:
							type = '自提';
							break;
						default:
							type = '安装';
							break;
					}
					this.installTimeLable = '实际' + type + '时间';

					//获取原订单设备编号
					if (this.orderDetail.oldOrderNum != null && this.orderDetail.oldOrderNum != '') {
						this.selectDeviceNumByOrderNum(this.orderDetail.oldOrderNum);
					}

					//自提单的是否自提默认为是
					let isSelfObj;
					if (this.orderDetail.orderType === 3) {
						isSelfObj = this.commonBoolChoiceList[0];
					} else {
						isSelfObj = this.commonBoolChoiceList[1];
					}
					this.onSelfPickUp(isSelfObj);
					//原充电桩处理方式
					if (
						this.orderDetail.oldDisposeType != null &&
						this.orderDetail.oldDisposeType != undefined
					) {
						this.onDisposeType(this.disposeTypeList[this.orderDetail.oldDisposeType - 1]);
					}

					//快递单据信息
					if (this.orderDetail.orderCourier != null) {
						this.orderCourier = this.orderDetail.orderCourier;
						this.handlePreviewImage(this.orderCourier.courierImageUrl, -4); //快递单据图
					}

					this.handlePreviewImage(resultData.reserve1, -1); //其他图片
					this.handlePreviewImage(resultData.reserve3, -2); //免责图
					this.handlePreviewImage(resultData.surveyImageUrl, -3); //勘测确认单
					this.handlePreviewImage(resultData.videoUrl, -99); //视频
					if (this.orderDetail.signExemption === 0) {
						this.signExemptionStr = '未签';
					} else if (this.orderDetail.signExemption === 1) {
						this.signExemptionStr = '已签';
					}
					if (this.orderDetail.purchaseExtension === 0) {
						this.purchaseExtensionStr = '未购买';
					} else if (this.orderDetail.purchaseExtension === 1) {
						this.purchaseExtensionStr = '已购买';
					}

					if (loadImageSetting) {
						await this.selectImageSetting();
					} else {
						await this.selectOrderImageSettingDetailList();
					}
				});
			},
			selectOrderImageSettingDetailList: async function () {
				this.axios({
					method: 'get',
					url: '/v1/web/device-image-setting/select-order-image-detail',
					params: {
						orderId: this.orderId,
					},
				}).then((res) => {
					let resultData = res.data.data;
					if (resultData != null && resultData.length > 0) {
						this.imageSetting = {};
						this.imageSetting.templateDetailList = resultData;
						for (let i = 0; i < this.imageSetting.templateDetailList.length; i++) {
							let image = this.imageSetting.templateDetailList[i];
							this.handlePreviewImage(image.url, image.id);
							this.$forceUpdate();
						}
					}
				});
			},
			selectImageSetting: async function () {
				let params = {
					orderType: this.orderDetail.orderType,
					repairType: this.orderDetail.orderType === 2 ? this.orderDetail.repairType : null,
					deviceCategoryId: this.orderDetail.categoryId,
					companyId: this.orderDetail.companyId,
				};

				this.axios({
					method: 'get',
					url: '/v1/web/device-image-setting/select-image-setting',
					params: params,
				}).then((res) => {
					if (res.data.data.list.length > 0) {
						this.imageSetting = res.data.data.list[0];
						this.selectOrderImageSettingDetailList();
					} else {
						Toast.fail('该充电桩类型未配置图片模板，请联系管理员');
						return;
					}
				});
			},
			selectDeviceNumByOrderNum(orderNum) {
				this.axios({
					method: 'get',
					url: '/v1/web/order-info/selectDeviceInfoByOrderNum',
					params: { orderNum: orderNum },
				}).then((res) => {
					let oldDevice = res.data?.data;
					if (oldDevice != null) {
						this.oldDeviceNum = oldDevice.deviceNum;
						//非换桩维修时，相关信息默认值
						if (this.orderDetail.orderType === 2 && this.orderDetail.repairType !== 1) {
							this.orderDetail.deviceNum = this.oldDeviceNum;
							this.orderDetail.powerSource = oldDevice.powerSource;
							this.orderDetail.cableBrandId = oldDevice.cableBrandId;
							this.orderDetail.cableBrandNameTmp = oldDevice.cableBrandName;
							this.orderDetail.cableBrandName = oldDevice.cableBrandName;
						}
					}
				});
			},
			checkImageSettingData() {
				if (
					this.imageSetting.templateDetailList != null &&
					this.imageSetting.templateDetailList.length > 0
				) {
					for (let i = 0; i < this.imageSetting.templateDetailList.length; i++) {
						let image = this.imageSetting.templateDetailList[i];
						if (image.isRequired === 1) {
							if (
								image.url === '' ||
								image.url == null ||
								image.miniUrl === '' ||
								image.miniUrl == null
							) {
								Toast.fail(image.imageName + '不能为空!');
								return false;
							}
						}
					}
				}

				return true;
			},
			onSubmit(submitType) {
				var isCheckByIsSelfPickup = true;
				if (this.orderDetail.isSelfPickup === 1) {
					isCheckByIsSelfPickup = false;
					this.orderDetail.powerSource = null;
					this.orderDetail.cableBrandId = null;
					this.orderDetail.cableBrandName = null;
					this.orderDetail.cableBrandNameTmp = null;
					this.orderDetail.lineLength = null;
					this.orderDetail.addOutlay = null;
					this.orderDetail.signExemption = null;
					this.orderImage.reserve3Uploader = [];
					this.orderDetail.purchaseExtension = null;
					this.orderDetail.materialOrderList = [];
				}

				if (this.orderDetail.signExemption === 0) {
					this.orderDetail.reserve3 = null;
				}

				this.orderDetail.orderCourier = null;
				if (this.orderCourier != null) {
					this.orderDetail.orderCourier = this.orderCourier;
				}

				this.orderDetail.submitType = submitType;
				if (submitType === 1) {
					if (this.orderDetail.detailAddress == '') {
						Toast.fail('详细地址不能为空');
						return;
					}
					if (
						this.orderDetail.deviceNum == '' &&
						this.checkRequiredUnSelfPickup(this.orderDetail)
					) {
						Toast.fail('充电桩编号不能为空');
						return;
					}
					if (
						this.orderDetail.categoryId == '' &&
						this.checkRequiredUnSelfPickup(this.orderDetail)
					) {
						Toast.fail('充电桩类型不能为空');
						return;
					}
					if (isCheckByIsSelfPickup) {
						if (
							this.orderDetail.powerSource == '' &&
							this.checkRequiredUnSelfPickup(this.orderDetail)
						) {
							Toast.fail('电源来源不能为空');
							return;
						}
						if (
							this.orderDetail.cableBrandName == '' &&
							this.checkRequiredUnSelfPickup(this.orderDetail)
						) {
							Toast.fail('电缆品牌不能为空');
							return;
						}
						if (
							this.orderDetail.lineLength == '' &&
							this.checkRequiredUnSelfPickup(this.orderDetail)
						) {
							Toast.fail('用线长度不能为空');
							return;
						}
						if (
							this.orderDetail.addOutlay == '' &&
							this.checkRequiredUnSelfPickup(this.orderDetail)
						) {
							Toast.fail('增项费用不能为空');
							return;
						}
						if (
							this.orderDetail.signExemption == null &&
							this.checkRequiredUnSelfPickup(this.orderDetail)
						) {
							Toast.fail('是否签免责不能为空');
							return;
						}
						if (
							this.orderDetail.purchaseExtension == null &&
							this.checkRequiredUnSelfPickup(this.orderDetail)
						) {
							Toast.fail('是否购买延长线不能为空');
							return;
						}
					}

					if (this.orderImage.reserve3Uploader == '' && this.orderDetail.signExemption === 1) {
						Toast.fail('免责图不能为空');
						return;
					}

					if (this.orderDetail.installTime == null) {
						Toast.fail('实际安装时间不能为空');
						return;
					}
					if (this.orderImage.surveyImageUploader == '') {
						Toast.fail('勘测确认单不能为空');
						return;
					}

					if (this.orderDetail.addOutlay > 0 && this.orderDetail.materialOrderList <= 0) {
						Toast.fail('增项费用大于0时超出后物料不能为空');
						return;
					}

					if (!this.checkImageSettingData()) {
						return;
					}

					this.$refs.completeRules
						.validate()
						.then(() => {
					this.requestSubmit(submitType);
						})
						.catch((e) => {
							console.log(e);
							Toast.fail('校验错误');
						});
				} else {
					// if (this.orderDetail.deviceNum == '' && this.checkShowDeviceNum(this.orderDetail)) {
					// 	Toast.fail('充电桩编号不能为空');
					// 	return;
					// }
					this.requestSubmit(submitType);
				}
			},
			requestSubmit(submitType) {
				this.orderDetail.orderImageSettingDetailList = this.imageSetting.templateDetailList;
				if (submitType === 0) {
					this.required = false;
				} else {
					this.required = true;
				}
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/complete-order/' + this.orderDetail.id,
					data: this.orderDetail,
				})
					.then((res) => {
						if (res.data.code === 200) {
							if (submitType === 0) {
								Toast.success('暂存成功');
							} else {
								Toast.success('提交成功');
							}
							this.$router.push({ path: '/OrderList', query: { type: '2' } });
						} else {
							Toast.fail(res.data.message);
						}
					})
					.catch(() => {
						Toast.fail('系统异常,请联系工作人员!');
					});
			},
			async afterRead(file, detail) {
				this.uploadImage(file.file, detail.name, false);
			},
			imageUploadOuther(file, detail) {
				if (file.length === undefined) {
					this.imageUpload(file, detail);
				} else {
					if (this.orderImage.reserve1Uploader.length + file.length >= 40) {
						Toast.fail('最多只能上传40张照片');
						return;
					}
					for (let i = 0; i < file.length; i++) {
						this.imageUpload(file[i], detail);
					}
				}
			},
			imageUpload(file, detail) {
				if (detail.name === -1) {
					if (this.orderImage.reserve1Uploader.length >= 40) {
						Toast.fail('最多只能上传40张照片');
						return;
					}
				}

				this.clickUpload(detail.name, file);
			},
			/**
			 * 压缩图片
			 * @param file
			 * @returns {Promise<unknown>}
			 */
			compressFile(file, quality) {
				return new Promise((resolve, reject) => {
					new ImageCompressor(file, {
						quality: quality, //压缩质量
						checkOrientation: false, //图片翻转，默认为false
						success(result) {
							resolve(result);
						},
						error(e) {
							reject(e);
						},
					});
				});
			},
			deleteImage(file, detail) {
				switch (detail.name) {
					case -1:
						//其他图片
						this.orderImage.reserve1Uploader.splice(detail.index, 1);
						this.orderDetail.reserve1 = this.getImageUrlArray(this.orderImage.reserve1Uploader);
						break;
					case -2:
						//免责图
						this.orderImage.reserve3Uploader.splice(detail.index, 1);
						this.orderDetail.reserve3 = this.getImageUrlArray(this.orderImage.reserve3Uploader);
						break;
					case -3:
						//勘测确认图
						this.orderImage.surveyImageUploader.splice(detail.index, 1);
						this.orderDetail.surveyImageUrl = this.getImageUrlArray(
							this.orderImage.surveyImageUploader
						);
						break;
					case -4:
						//快递单据图
						this.orderCourier.courierImageUploader = [];
						this.orderCourier.courierImageUrl = '';
						break;
					case -99:
						this.orderImage.videoUploader = [];
						this.orderDetail.videoUrl = '';
						break;
					default:
						//模板图片
						if (
							this.imageSetting.templateDetailList != null &&
							this.imageSetting.templateDetailList.length > 0
						) {
							let image = this.imageSetting.templateDetailList.find((x) => x.id === detail.name);
							image.imageUploader = [];
							image.url = '';
							image.miniUrl = '';
							this.$forceUpdate();
						}
						break;
				}
			},
			async clickUpload(type, file) {
				this.showLoading = true;
				let miniFile = file;
				if (type > 0) {
					let miniSize = 100 * 1024;
					if (file.size > miniSize) {
						miniFile = await this.compressFile(file, 0.1);
					}
				}
				let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
				if (file.size > maxSize) {
					file = await this.compressFile(file, 0.2);
				}
				this.uploadImage(file, type, false);
				if (type > 0) {
					this.uploadImage(miniFile, type, true);
				}
			},
			uploadImage(file, type, isMini) {
				let param = new FormData(); //创建form对象
				param.append('files', file);
				param.append('orderNum', this.orderDetail.orderNum);
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/image-upload',
					data: param,
				})
					.then((res) => {
						let imgUrl = res.data.data.imageUrl;
						if (!isMini) {
							this.handlePreviewImage(imgUrl, type);
							this.flagUpload = true;
						} else {
							this.setMiniImage(imgUrl, type);
						}
						this.showLoading = false;
					})
					.catch(() => {
						Toast.fail('上传失败');
						this.showLoading = false;
					});
			},

			scanCode() {
				this.$router.push({ path: '/QCode', query: { codeType: 'orderCode', isOpenScan: true } });
			},
			setMiniImage(miniImgUrl, type) {
				//压缩图片
				let miniImageObj = {};
				miniImageObj.url = this.transformUrl(miniImgUrl);
				if (type <= 0) {
					return;
				} else {
					//模板图片
					if (
						this.imageSetting.templateDetailList != null &&
						this.imageSetting.templateDetailList.length > 0
					) {
						let image = this.imageSetting.templateDetailList.find((x) => x.id === type);
						image.miniUrl = miniImgUrl;
					}
				}
			},
			handlePreviewImage(imgUrl, type) {
				if (imgUrl === undefined || imgUrl === null || imgUrl === 'deleteImage' || imgUrl == '') {
					return;
				}
				//默认图片
				let imageObj = {};
				imageObj.url = this.transformUrl(imgUrl);
				let imageArray;
				switch (type) {
					//其他图片
					case -1:
						imageArray = imgUrl.split(',');
						if (imageArray.length > 1) {
							for (let i = 0; i < imageArray.length; i++) {
								let image = {};
								image.url = this.transformUrl(imageArray[i]);
								this.orderImage.reserve1Uploader.push(image);
							}
						} else {
							this.orderImage.reserve1Uploader.push(imageObj);
						}
						this.orderDetail.reserve1 = this.getImageUrlArray(this.orderImage.reserve1Uploader);
						break;
					case -2:
						//免责图
						imageArray = imgUrl.split(',');
						if (imageArray.length > 1) {
							for (let i = 0; i < imageArray.length; i++) {
								let image = {};
								image.url = this.transformUrl(imageArray[i]);
								this.orderImage.reserve3Uploader.push(image);
							}
						} else {
							this.orderImage.reserve3Uploader.push(imageObj);
						}
						this.orderDetail.reserve3 = this.getImageUrlArray(this.orderImage.reserve3Uploader);
						break;
					//勘测确认图
					case -3:
						imageArray = imgUrl.split(',');
						if (imageArray.length > 1) {
							for (let i = 0; i < imageArray.length; i++) {
								let image = {};
								image.url = this.transformUrl(imageArray[i]);
								this.orderImage.surveyImageUploader.push(image);
							}
						} else {
							this.orderImage.surveyImageUploader.push(imageObj);
						}
						this.orderDetail.surveyImageUrl = this.getImageUrlArray(
							this.orderImage.surveyImageUploader
						);
						break;
					//快递单据图
					case -4:
						this.orderCourier.courierImageUploader = [];
						this.orderCourier.courierImageUploader.push(imageObj);
						this.orderCourier.courierImageUrl = imgUrl;
						break;
					//视频
					case -99:
						imageObj.type = 'video';
						imageObj.isVideo = true;
						imageObj.file = new File([], '视频', {});
						this.orderImage.videoUploader = [];
						this.orderImage.videoUploader.push(imageObj);
						this.orderDetail.videoUrl = imgUrl;
						break;
					//模板图片
					default:
						if (
							this.imageSetting.templateDetailList != null &&
							this.imageSetting.templateDetailList.length > 0
						) {
							let image = this.imageSetting.templateDetailList.find((x) => x.id === type);
							image.imageUploader = [];
							image.imageUploader.push(imageObj);
							image.url = imgUrl;
						}
						break;
				}
			},
			transformUrl(url) {
				return '/api/v1/web/order-info/preview-image?path=' + url;
			},
			getImageUrlArray(imageObjs) {
				let resultStr = '';
				for (let i = 0; i < imageObjs.length; i++) {
					if (resultStr !== '') {
						resultStr = resultStr + ',';
					}
					resultStr = resultStr + imageObjs[i].url.substring(imageObjs[i].url.indexOf('=') + 1);
				}
				return resultStr;
			},
			classChangeDevice(value) {
				if (value == null || value == undefined) {
					Toast.fail('您的库存中没有任何该类型的充电桩，请联系管理员。');
					this.showChangeDevice = false;
					return;
				}

				this.orderDetail.deviceNum = value.deviceNum;
				this.showChangeDevice = false;
			},
			onInput() {
				let newArray = [];
				for (let i = 0; i < this.changeDeviceList.length; i++) {
					if (this.changeDeviceList[i].deviceNum.indexOf(this.searchDevice) >= 0) {
						newArray.push(this.changeDeviceList[i]);
					}
				}
				if (this.searchDevice === '' || this.searchDevice === undefined) {
					this.changeDeviceList = this.oldChangeDeviceList;
				} else {
					this.changeDeviceList = [];
					this.changeDeviceList = newArray;
				}
			},
			getDeviceByCategory() {
				let params = {
					numNotNull: 'true',
					status: 0,
					isContainsGroup: true,
					haveUser: localStorage.getItem('userId'),
					categoryId: this.orderDetail.storeId,
					companyId: this.orderDetail.companyId,
				};
				this.axios({
					method: 'get',
					url: '/v1/web/device-manage/list-device-manage',
					params: params,
				}).then((res) => {
					this.changeDeviceList = res.data.data.list;
					this.oldChangeDeviceList = res.data.data.list;
				});
			},
			calcMaterialTotal(row) {
				row.allPrice = row.meterialPrice * row.num;
				row.actualAmount = row.allPrice;
			},
			checkShowUnSelfPickup(row) {
				return (
					row.deviceNum !== '' &&
					row.isSelfPickup !== 1 &&
					(row.orderType !== 2 || (row.orderType === 2 && row.repairType === 1))
				);
			},
			checkRequiredUnSelfPickup(row) {
				return (
					row.isSelfPickup !== 1 &&
					(row.orderType !== 2 || (row.orderType === 2 && row.repairType === 1))
				);
			},
			checkShowDeviceNum(row) {
				return !(
					row.orderType === 2 &&
					row.repairType !== 1 &&
					(this.oldDeviceNum == null || this.oldDeviceNum == '')
				);
			},
			selectFollowDetail(){
				//获取跟进列表
				this.axios({
					method: 'get',
					//后端接口
					url: '/v1/web/order-info/get-order-all-follow/'+this.orderId,
				}).then((res) => {
					this.followList = res.data.data;
				});
			},
			formattedFollowInfo(item){
				if (item.followTime == null || item.followTime == undefined || item.followTime == '') {
					return '';
				}
				item.followTime = moment(item.followTime).format('YYYY-MM-DD');
				return `${item.followTime} (${item.followerName}): ${item.followInfo}`;
			}
		},
	};
</script>

<style>
	.van-field__label {
		color: rgb(120, 120, 120) !important;
	}

	.van-field__control {
		color: rgb(180, 180, 180) !important;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		background-color: #fff;
	}
	.row-text {
		font-size: small;
		color: rgb(120, 120, 120) !important;
	}
</style>
