/**
 * 数据格式化相关方法
 */

export function statusFormat(row) {
    return statusFormatByStatus(row.status);
}

export function statusFormatByStatus(status) {
    if (status === 8) {
        return "待派单";
    } else if (status === 1) {
        return "待首联";
    } else if (status === 2) {
        return "待安装";
    } else if (status === 3) {
        return "待审核";
    } else if (status === 4) {
        return "被驳回";
    } else if (status === 5) {
        return "暂不安装";
    } else if (status === 6) {
        return "待结算";
    } else if (status === 7) {
        return "已结算";
    } else if (status === 9) {
        return "电表申请中";
    } else if (status === 10) {
        return "等客户通知";
    } else if (status === 11) {
        return "取消待审核";
    } else if (status === 12) {
        return "已取消";
    }
}
export function orderTypeFormat(row) {
    switch (row.orderType) {
        case 1:
            return "新订单";
        case 2:
            return "维修单";
        case 3:
            return "自提单";
        default:
            return '';
    }
}
export function repairTypeFormat(row) {
    switch (row.repairType) {
        case 1:
            return "更换充电桩";
        case 2:
            return "更换漏保";
        case 3:
            return "地线维修";
        case 4:
            return "其他维修";
        default:
            return '';
    }
}

export function disposeTypeFormat(row) {
    switch (row.oldDisposeType) {
        case 1:
            return "保留";
        case 2:
            return "邮寄";
        default:
            return '';
    }
}